<template>
  <div id="user.activate.register">
    <b-row class="m-0 p-4">
        <b-col md="3"></b-col>
        <b-col md="6">
          <b-card>
            <p class="text-center">
                Succesfully registered <br>
                Activate your account with the email in your inbox
            </p>
          </b-card>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
  </div>
</template>

<script>
export default {
    id: 'user.activate.register'
}
</script>

<style>

</style>